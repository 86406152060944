import { Button } from "antd";
import classNames from "classnames";
import moment from "moment";
import React, { useRef, useState } from "react";
import { HiOutlineFolder } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import AdminService from "../../../service/AdminService";
import PublicService from "../../../service/PublicService";
import UserService from "../../../service/UserService";
import { ExpandedCardVersionTwo as ExpandedCard } from "./Cards";
import { Filter, Partner, Rejected, XLSICON } from "./Svgs";

const Index = () => {
  const [stats, setStats] = useState({
    neueEmpfehlungen: 0,
    offeneVorgange: 0,
    empfehlungen: 0,
    aufrufe: 0,
    aufrufeProPromoter: 0,
    promoter: 0,
    eingelostePramien: 0,
    promoterOhneAufrufe: 0,
    promoterOhneEinwilligung: 0,
    kundenOhnePromoter: 0,
    allPartners: 0,
    newPartners: 0,
    partnerDemo: 0,
    partnerTest: 0,
  });
  const navigate = useNavigate();
  const [expanded, setexpanded] = useState(-1);
  const [selection, setSelection] = useState("transparent");
  const [topValue, setTopValue] = useState(330);
  const [list, setList] = useState([]);
  const [rawList, setRawList] = useState([]);
  const [highlightedCard, setHighlightedCard] = useState("");
  const getPartners = async () => {
    try {
      const { data } = await AdminService?.listUsers();

      setRawList(data?.users);

      const { data: dashboardStats } = await AdminService.getDashboardStats();
      setStats(dashboardStats);
    } catch (error) {}
  };
  React.useEffect(() => {
    try {
      getPartners();
      const getStats = async () => {
        const { data: dashboardStats } = await AdminService.getDashboardStats();
        setStats(dashboardStats);
      };
      getStats();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Sort and Filter List
  React.useEffect(() => {
    const filteredAndSortedData = () => {
      const sortedData = [...rawList].sort((a, b) => {
        if (a._id === highlightedCard) return -1;

        const colorA = getActualColor(a);
        const colorB = getActualColor(b);

        const calculateScore = (a) => {
          if (a === "#0084F7") return 1;
          if (a === "#FFA500") return 2;
          if (a === "#001D47") return 3;
          if (a === "#ADADAD") return 4;
          if (a === "#F60002") return 5;
        };

        const scoreA = calculateScore(colorA);
        const scoreB = calculateScore(colorB);

        return scoreA - scoreB;
      });

      return sortedData.filter((a) => {
        if (selection === "transparent") return true;

        return selection === getActualColor(a);
      });
    };

    const updateListIfNecessary = (sortedData) => {
      if (JSON.stringify(sortedData) !== JSON.stringify(list)) {
        setList(sortedData);
      }
    };

    const updateExpandedIfNecessary = (sortedData) => {
      const oldIndex = expanded;
      const ex = list[oldIndex]?._id;
      const newIndex = sortedData.findIndex((item) => item._id === ex);

      // Nur updaten, wenn sich der Index ändert
      if (oldIndex !== newIndex) {
        setexpanded(newIndex);
      }
    };
    if (rawList.length > 0) {
      const sortedData = filteredAndSortedData();

      updateListIfNecessary(sortedData);
      updateExpandedIfNecessary(sortedData);
    }
  }, [highlightedCard, list, expanded, rawList, selection]);

  const query = window.location.href;
  React.useEffect(() => {
    if (query.split("?")[1]) {
      const queryRep = query.split("?")[1].split("=");
      if (queryRep[0] === "partner") {
        const partnerId = queryRep[1];
        setHighlightedCard(partnerId);

        if (list.length > 0) {
          const newIndex = list.findIndex((item) => item._id === partnerId);
          console.log(newIndex);
          if (newIndex >= 0 && expanded !== newIndex) {
            setexpanded(newIndex);
          }
        }
      }
    }
  }, [query, list]);

  const [adminTestPhase, setAdminTestPhase] = useState(null);
  const getAdminConfigure = async () => {
    const response = await PublicService.getAdminConfigure();
    setAdminTestPhase(response?.data?.config?.testPhaseDurationDays);
  };
  React.useEffect(() => {
    getAdminConfigure();
  }, []);

  function getActualColor(user) {
    const getFourWeeksFromNow = () => {
      const oneMonthFromNow = new Date();
      oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
      return oneMonthFromNow;
    };
    const oneMonthFromNow = getFourWeeksFromNow();

    const isTestPhase = (
      user?.firstAccessAt ? moment(user?.firstAccessAt) : moment()
    )
      .add(adminTestPhase ?? 14, "days")
      .isAfter(moment());

    const abgelaufen =
      !isTestPhase && !user?.subscription?.paid && user?.subscribedOnce;

    const inKundigung =
      !user?.autoRenewals &&
      (user?.finalAboDate ? moment(user?.finalAboDate) : moment())
        .subtract(30, "days")
        .isBefore(moment()) &&
      !abgelaufen;

    if (
      isTestPhase &&
      !inKundigung &&
      !user?.subscription?.paid &&
      !abgelaufen
    ) {
      return "#0084F7"; // light-blue
    } else if (inKundigung) {
      return "#FFA500"; // orange
    } else if (user?.subscription?.paid) {
      return "#001D47"; // dark-blue
    } else if (abgelaufen) {
      return "#F60002"; // red
    } else {
      return "#ADADAD"; // turquoise
    }
  }
  const cardsCollectionRef = useRef();
  return (
    <div>
      {" "}
      <div className="flex h-full flex-wrap justify-between gap-10 w-full ">
        <div
          className="  h-full w-full sm:p-5 p-2 bg-[#fafafa] rounded-[30px] overflow-auto "
          style={{ width: "auto" }}
        >
          <h1 className="font-semibold sm:text-9xl text-xl">
            Partnerstatistiken im Überblick.
          </h1>
          <div className="overflow-x-scroll py-5 flex h-[100%]  items-center sm:gap-4 gap-1 ">
            <div
              className={classNames(
                `text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]`
              )}
              onClick={() => {
                setSelection("transparent");
              }}
              style={{
                background: "transparent",
              }}
            >
              <span className="green-mark-upper  text-xl justify-center  font-semibold flex items-center">
                {stats?.allPartners ?? 0}
              </span>
              <p className="font-[500] text-[14px] text-[#868686]">
                Partner <br />
                gesamt
              </p>
              <p className="flex justify-center items-center">
                <Partner />
              </p>
            </div>

            <div
              className={classNames(
                `text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]`,
                {
                  " text-whitner-second  ": selection === "#001D47",
                }
              )}
              onClick={() => {
                setSelection("#001D47");
              }}
              style={{
                background: selection === "#001D47" ? "#001D47" : "transparent",
              }}
            >
              <span className="green-mark-upper  text-xl justify-center  font-semibold flex items-center">
                {
                  rawList.filter((a) => {
                    return "#001D47" === getActualColor(a);
                  }).length
                }
              </span>
              <p className="font-[500] text-[14px] text-[#868686]">
                Partner mit <br />
                aktivem Abo
              </p>
              <p className="flex justify-center items-center">
                <Partner />
              </p>
            </div>

            <div
              className={classNames(
                `text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]`,
                {
                  " text-whitner-second  ": selection === "#FFA500",
                }
              )}
              onClick={() => {
                setSelection("#FFA500");
              }}
              style={{
                background: selection === "#FFA500" ? "#FFA500" : "transparent",
              }}
            >
              <span className="green-mark-upper  text-xl justify-center  font-semibold flex items-center">
                {
                  rawList.filter((a) => {
                    return "#FFA500" === getActualColor(a);
                  }).length
                }
              </span>
              <p className="font-[500] text-[14px] text-[#868686]">
                Partner in <br />
                Kündigung
              </p>
              <p className="flex justify-center items-center">
                <Partner />
              </p>
            </div>

            <div
              className={classNames(
                `text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]`,
                {
                  " text-whitner-second  ": selection === "#ADADAD",
                }
              )}
              onClick={() => {
                setSelection("#ADADAD");
              }}
              style={{
                background: selection === "#ADADAD" ? "#ADADAD" : "transparent",
              }}
            >
              <span className="green-mark-upper text-xl w-fit m-auto font-semibold ">
                {
                  rawList.filter((a) => {
                    return "#ADADAD" === getActualColor(a);
                  }).length
                }
              </span>
              <p className="font-[500] text-[14px] ">
                Partner mit <br /> abgelaufener Testphase
              </p>
              <p className="flex justify-center items-center">
                <Partner />
              </p>
            </div>

            <div
              className={classNames(
                `text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]`,
                {
                  " text-whitner-second  ": selection === "#0084F7",
                }
              )}
              onClick={() => {
                setSelection("#0084F7");
              }}
              style={{
                background: selection === "#0084F7" ? "#0084F7" : "transparent",
              }}
            >
              <span className="green-mark-upper text-xl w-fit m-auto font-semibold ">
                {
                  rawList.filter((a) => {
                    return "#0084F7" === getActualColor(a);
                  }).length
                }
              </span>
              <p className="font-[500] text-[14px] ">
                Partner in <br />
                Testphase
              </p>
              <p className="flex justify-center items-center">
                <Partner />
              </p>
            </div>

            <div
              className={classNames(
                `text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]`,
                {
                  " text-whitner-second  ": selection === "#F60002",
                }
              )}
              onClick={() => {
                setSelection("#F60002");
              }}
              style={{
                background: selection === "#F60002" ? "#F60002" : "transparent",
              }}
            >
              <span className="green-mark-upper text-xl w-fit m-auto font-semibold ">
                {
                  rawList.filter((a) => {
                    return "#F60002" === getActualColor(a);
                  }).length
                }
              </span>
              <p className="font-[500] text-[14px] ">
                Partner mit <br />
                abgelaufenem Abo
              </p>
              <p className="flex justify-center items-center">
                <Partner />
              </p>
            </div>
          </div>
          <div className="flex items-center gap-10 mt-5">
            <div className="flex items-center text-[#868686] gap-4">
              <Filter />
              <p>
                Filtere direkt nach Personen, indem du die Status-Karte
                anklickst.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="border-[1px] my-10 border-[#d8d8d8] bg-[#fafafa]   rounded-[30px]">
        <div className=" p-5 mt-5">
          <h1 className="text-[28px]  font-semibold">Alle Partner</h1>
          <div className="flex items-center gap-5 text-[#868686]">
            <HiOutlineFolder />
            <p>
              Finde mehr Informationen zu dem jeweiligen Partner, durchs klicken
              auf die Karte
            </p>
            {/* <Button
              type="primary"
              className="bg-[#001D47]  flex items-center gap-3 rounded-[10px] text-white"
            >
              <XLSICON /> <p>Excel Export</p>
            </Button> */}
          </div>
        </div>
        <div className=" h-full">
          <div
            className="relative transition-all secondary:mt-[100px] secondary:mb-[100px] mt-[200px]  "
            ref={cardsCollectionRef}
            style={{
              height: cardsCollectionRef?.current?.scrollHeight,
            }}
          >
            {list.map((_, i) => {
              return (
                <ExpandedCard
                  expanded={expanded === i}
                  data={_}
                  manipulateTop={setTopValue}
                  top={topValue}
                  className={"absolute transition-all !duration-1000 "}
                  bg={getActualColor(_)}
                  getPartners={getPartners}
                  setList={async ({ key, value }) => {
                    try {
                      setList((prev) =>
                        [...prev].map((e) => {
                          if (e._id !== _._id) {
                            return e;
                          } else {
                            return { ...e, [key]: value };
                          }
                        })
                      );
                      await AdminService.updateUserDetails({
                        user_id: _._id,
                        data: {
                          ..._,
                          [key]: value,
                        },
                      }).then(async () => {
                        await getPartners();
                      });
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  props={{
                    style: {
                      zIndex: i * 100,
                      top:
                        expanded === null || i <= expanded
                          ? i * 100
                          : i * 100 + topValue,
                    },
                    onClick: (callback) => {
                      if (expanded !== i) {
                        setexpanded(i);
                        if (callback) callback();
                      }
                    },
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
