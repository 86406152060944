import { Card, Image } from "antd";
import React from "react";
import { sendEventUser } from "../../../../../utils/analytics";
import { imageBackground } from "../../../../../utils/setImageBackground";
import Video from "../../shared/Video";
import WhatsappButton from "../../shared/WhatsappButton";

const HeroStromGas = ({ landingPageData }) => {
  const {
    transformed_accompanyingTextOne,
    transformed_accompanyingTextTwo,
    transformed_introductoryText,
    transformed_whatsappText,
    whatsappNumber,
    background_image,
    logoSrc,
    primary_image,
    whatsappButtonTextOne,
    whatsappButtonTextTwo,
    persentationVideo,
    thumbnail,
    activateWhatsApp,
    activate_wa1,
    activate_wa2,
  } = landingPageData;

  return (
    <div className="min-h-screen bg-gray-100 relative">
      {/* Logo Section */}
      <div className="w-full bg-gray-200 secondary:p-2 pr-4 pl-4 pb-0 text-center">
        <img
          src={logoSrc || "/placeholder.svg?height=60&width=200"}
          alt="Dein Logo"
          width={200}
          height={60}
          className="mx-auto secondary:mt-12"
        />
      </div>
      <div
        style={{
          ...imageBackground(
            background_image === ""
              ? background_image
              : "https://res.cloudinary.com/dcwsmnhb9/image/upload/d3gtt720uosnvt39swj9.webP"
          ),
          minHeight: "100%",
        }}
        className="absolute top-0 left-0 right-0 bottom-0 z-[-1]"
      />
      {/* Desktop */}
      <div className="container mx-auto px-4 pb-4 hidden secondary:block">
        <div className="relative">
          <div className="relative grid md:grid-cols-[20%_80%] gap-8 p-2">
            <div className="space-y-8 h-full">
              <Card className="p-1 bg-white rounded-11xl shadow-lg h-full">
                <div
                  dangerouslySetInnerHTML={{
                    __html: transformed_accompanyingTextTwo,
                  }}
                />
              </Card>
            </div>

            {/* Right Column - Content */}
            <div className="flex flex-col justify-evenly">
              <div className="relative w-full -translate-x-12 translate-y-0 ">
                <div
                  className="bg-white p-8 text-black text-center text-2xl font-bold relative w-[100%]"
                  style={{
                    clipPath:
                      "polygon(0 11%, 79% 11%, 79% 0, 100% 50%, 79% 100%, 79% 89%, 0 89%)",
                  }}
                >
                  <div
                    className="flex items-center justify-center h-full mr-40 my-4"
                    dangerouslySetInnerHTML={{
                      __html: transformed_introductoryText,
                    }}
                  />
                </div>
              </div>

              {/* Profile Section */}
              <div className="relative flex flex-col items-center w-full justify-between mb-20 -translate-x-[10%]">
                <div
                  className="relative border-white w-[40%] border-4 !bg-top aspect-[4/5]"
                  style={{
                    ...imageBackground(primary_image),
                  }}
                >
                  {activate_wa1 && (
                    <WhatsappButton
                      number={whatsappNumber}
                      whatsappText={transformed_whatsappText}
                      className="!text-sm absolute left-0 bottom-0 z-10"
                      text={whatsappButtonTextOne}
                      activateWhatsApp={activateWhatsApp}
                      type="stormgas"
                      sendGoogleAnalytics={() =>
                        sendEventUser({
                          category: "button",
                          action: "click_wa1",
                          label: "wa1",
                          value: 1,
                          landingPageId: landingPageData._id,
                        })
                      }
                    />
                  )}
                </div>
                <div className="absolute bottom-0 right-0 w-full flex flex-row gap-4 items-end justify-end translate-y-[50%]">
                  <div className="bg-white p-6 rounded-3xl shadow-md mt-6 text-center w-[35%]">
                    <p
                      className="text-lg font-semibold"
                      dangerouslySetInnerHTML={{
                        __html: transformed_accompanyingTextOne,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="#middle" className="block mt-32 text-center">
            <img
              src="/images/icons-8-doppelt-runter-21-schwarz.png"
              alt=""
              className="animate-bounce w-[32px] sm:w-[50px] inline-block"
            />
          </a>
        </div>
      </div>
      {/* Mobile */}
      <div className="space-y-8 h-full pb-6 pt-6 secondary:px-20 px-6 secondary:hidden">
        <div
          className="flex items-center justify-center h-full"
          dangerouslySetInnerHTML={{
            __html: transformed_introductoryText,
          }}
        />
        <Card className="p-6 bg-white rounded-11xl shadow-lg h-full">
          <div
            dangerouslySetInnerHTML={{
              __html: transformed_accompanyingTextTwo,
            }}
          />
        </Card>
        <Video
          url={persentationVideo}
          thumbnail={thumbnail}
          className="secondary:hidden py-10 px-2"
          textWhite
          rounded={false}
          type="stormgas"
          heading={landingPageData.transformed_introVideoHeader}
          textBackground={landingPageData.activateTextWithBackground}
        />
        {activate_wa1 && (
          <WhatsappButton
            number={whatsappNumber}
            whatsappText={transformed_whatsappText}
            className="!text-xs"
            text={whatsappButtonTextOne}
            activateWhatsApp={activateWhatsApp}
            type="stormgas"
            sendGoogleAnalytics={() =>
              sendEventUser({
                category: "button",
                action: "click_wa1",
                label: "wa1",
                value: 1,
                landingPageId: landingPageData._id,
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default HeroStromGas;
