import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { HiOutlineFolder } from "react-icons/hi";
import AdminService from "../../../service/AdminService";
import { XLSICON } from "../partner/Svgs";
import { ExpandedCardVersionTwo as ExpandedCard } from "./Cards";
import ImportModule from "./ImportModule";

const Index = () => {
  const [expanded, setexpanded] = useState(null);
  const [topValue, setTopValue] = useState(330);
  const [list, setList] = useState([]);
  const getPartners = async () => {
    try {
      const { data } = await AdminService.getLeads();

      setList(data?.leads);
    } catch (error) {}
  };
  useEffect(() => {
    getPartners();
  }, []);

  function getActualColor(user) {
    return "#0084F7"; // turquoise
  }
  const cardsCollectionRef = useRef();
  return (
    <div>
      <div className="border-[1px] my-10 border-[#d8d8d8] bg-[#fafafa]   rounded-[30px]">
        <div className=" p-5 mt-5">
          <h1 className="text-[28px]  font-semibold">Alle Leads</h1>
          <div className="flex items-center gap-5 text-[#868686]">
            <HiOutlineFolder />
            <p>
              Finde mehr Informationen zu dem jeweiligen Lead, durchs klicken
              auf die Karte
            </p>

            <ImportModule
              ImportButton={({ onClick }) => (
                <Button
                  onClick={onClick}
                  type="primary"
                  className="bg-[#001D47]  flex items-center gap-3 rounded-[10px] text-white"
                >
                  <XLSICON /> Excel Import{" "}
                </Button>
              )}
              onImportEnd={async () => {
                getPartners();
              }}
            />
          </div>
        </div>
        <div className=" h-full">
          <div
            className="relative    transition-all secondary:mt-0 mt-[200px]  "
            ref={cardsCollectionRef}
            style={{
              height: cardsCollectionRef?.current?.scrollHeight,
            }}
          >
            {list.map((_, i) => {
              return (
                <ExpandedCard
                  expanded={expanded === i}
                  data={_}
                  manipulateTop={setTopValue}
                  top={topValue}
                  className={"absolute transition-all !duration-1000 "}
                  bg={getActualColor(_)}
                  getPartners={getPartners}
                  setList={async ({ key, value }) => {
                    try {
                      setList((prev) =>
                        [...prev].map((e) => {
                          if (e._id !== _._id) {
                            return e;
                          } else {
                            return { ...e, [key]: value };
                          }
                        })
                      );
                      await AdminService.updateLeadDetails({
                        id: _._id,
                        data: {
                          ..._,
                          [key]: value,
                        },
                      }).then(async () => {
                        await getPartners();
                      });
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  props={{
                    style: {
                      zIndex: i * 100,
                      top:
                        expanded === null || i <= expanded
                          ? i * 100
                          : i * 100 + topValue,
                    },
                    onClick: (callback) => {
                      if (expanded !== i) {
                        setexpanded(i);
                        if (callback) callback();
                      }
                    },
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
