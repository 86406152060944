import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendEventUser } from "../../../../../utils/analytics";
import { replaceAndMark } from "../../../../../utils/replaceAndMarkText";
import { replaceText } from "../../../../../utils/replaceText";
import { imageBackground } from "../../../../../utils/setImageBackground";
import TypeButton from "../../shared/TypeButton";
import Video from "../../shared/Video";
import WhatsappButton from "../../shared/WhatsappButton";
import Footer from "../footer/Footer";
import Hero from "../hero/Hero";
import LocalReviews from "../localReviews/LocalReviews";
import PortraitAndText from "../portraitAndText/PortraitAndText";

const StromGasView = ({
  landingPageData,
  clickid,
  promoter,
  localReviews,
  promoter_id,
  landingPageId,
  user,
}) => {
  const type = "stormgas";
  const navigate = useNavigate();
  const [onlyImage, setOnlyImage] = useState(false);
  const {
    activateLocalReviews,
    whatsappButtonTextOne,
    whatsappButtonTextTwo,
    persentationVideo,
    presentationVideoDuration,
    thumbnail,
    questionaireIntroductionText,
    questionaireButtonText,
    whatsappText,
    whatsappNumber,
    activateWhatsApp,
    logo,
    localReviewsHeader,
    introVideoHeader,
    accompanyingTextOne,
    accompanyingTextTwo,
    accompanyingTextThree,
    introductoryText,
    activateBackgroundAccompanyingTextOne,
    activateBackgroundAccompanyingTextTwo,
    activateBackgroundAccompanyingTextThree,
    activateBackgroundVideoText,
    primary_image,
    activate_wa1,
    activate_wa2,
  } = landingPageData;

  function containsOnlyImage(htmlString) {
    // Erstelle ein DOM-Element, um die HTML-String zu analysieren
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // Alle <p>-Tags extrahieren
    const paragraphs = doc.querySelectorAll("p");

    // Schleife durch die <p>-Tags und prüfe ihren Inhalt
    let hasImage = false;
    for (const p of paragraphs) {
      const img = p.querySelector("img");
      const textContent = p.textContent.trim();

      // Prüfen, ob das <p> nur ein <img> enthält
      if (img && !textContent) {
        if (hasImage) {
          // Mehr als ein Bild gefunden
          return false;
        }
        hasImage = true;
      } else if (img === null && textContent.length > 0) {
        return false;
      }
    }
    return hasImage;
  }

  useEffect(() => {
    const only = containsOnlyImage(
      landingPageData.transformed_accompanyingTextThree
    );
    setOnlyImage(only);
  }, []);

  // Textersetzung
  [
    "introductoryText",
    "accompanyingTextOne",
    "accompanyingTextTwo",
    "accompanyingTextThree",
    "introVideoHeader",
    "questionaireIntroductionText",
  ].forEach((key) => {
    let temp = replaceAndMark(
      landingPageData[key],
      {
        "{Promoter Vorname}": ` ${promoter.firstname} `,
        "{Promoter Nachname}": ` ${promoter.lastname} `,
      },
      "#00DEF7"
    );
    landingPageData[`transformed_${key}`] = temp;
  });

  const promoterReplacements = {
    "{Promoter Vorname}": ` ${promoter.firstname} `,
    "{Promoter Nachname}": ` ${promoter.lastname} `,
  };

  const textMappings = {
    transformed_whatsappText: whatsappText,
    transformed_introVideoHeader: introVideoHeader,
    transformed_questionaireIntroductionText: questionaireIntroductionText,
    transformed_accompanyingTextOne: accompanyingTextOne,
    transformed_accompanyingTextTwo: accompanyingTextTwo,
    transformed_accompanyingTextThree: accompanyingTextThree,
    transformed_introductoryText: introductoryText,
  };

  Object.keys(textMappings).forEach((key) => {
    landingPageData[key] = replaceText(textMappings[key], promoterReplacements);
  });

  landingPageData.transformed_introVideoHeader = replaceText(introVideoHeader, {
    ...promoterReplacements,
    "{Videolaenge}": Math.round(presentationVideoDuration),
  });

  const withBackground = (set) => {
    return set ? "bg-[#000000] p-1 rounded-sm bg-opacity-50" : "";
  };

  const isEmptyHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText.trim() === "";
  };

  const shouldHideTextBlockTwo = isEmptyHtml(
    landingPageData.transformed_accompanyingTextTwo
  );

  // Google Analytics PageView
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sendEventUser({
        category: "page",
        action: "page_viewLP",
        label: "lppageview",
        value: 1,
        landingPageId: landingPageId,
      });
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [landingPageId]);

  const googleAnalyticsHandler = (label) => {
    sendEventUser({
      category: "button",
      action: `click_${label}`,
      label: label,
      value: 1,
      landingPageId: landingPageId,
    });
  };

  return (
    <div className="w-full">
      <Hero landingPageData={landingPageData} type={type} promoter={promoter} />

      <div className="bg-white">
        <LocalReviews
          localReviews={localReviews}
          activateLocalReviews={activateLocalReviews}
          localReviewsHeader={localReviewsHeader}
          type={type}
          logo={logo}
        />

        <Video
          url={persentationVideo}
          thumbnail={thumbnail}
          className="!text-white hidden secondary:block pt-14"
          type={type}
          heading={landingPageData.transformed_introVideoHeader}
          landingPageId={landingPageId}
          videoTextBG={activateBackgroundVideoText}
        />
        {activate_wa2 && (
          <WhatsappButton
            activateWhatsApp={activateWhatsApp}
            number={whatsappNumber}
            whatsappText={landingPageData.transformed_whatsappText}
            text={whatsappButtonTextTwo}
            className="mx-4 mt-14 hidden secondary:block"
            type={type}
            sendGoogleAnalytics={() => googleAnalyticsHandler("wa2")}
          />
        )}

        <PortraitAndText
          landingPageData={landingPageData}
          className="secondary:hidden pt-14 flex flex-col gap-10 items-center"
        />
        {activate_wa2 && (
          <WhatsappButton
            activateWhatsApp={activateWhatsApp}
            number={whatsappNumber}
            whatsappText={landingPageData.transformed_whatsappText}
            text={whatsappButtonTextOne}
            className="mx-4 block secondary:hidden mt-4 mb-12"
            type={type}
            sendGoogleAnalytics={() => googleAnalyticsHandler("wa2")}
          />
        )}

        <div
          className={`text-center secondary:text-none secondary:px-32 secondary:mb-32 secondary:mt-32 mb-12 mt-12 rounded-sm sm:bg-transparent sm:text-[#000000] ${
            onlyImage ? "" : "bg-slate-900 px-12 p-4"
          } text-white sm:p-0 break-words sm:text-inherit text-whiteImportant mx-4 sm:mx-0`}
        >
          <p
            className="text-2xl font-bold whitespace-pre-line"
            dangerouslySetInnerHTML={{
              __html: landingPageData.transformed_accompanyingTextThree,
            }}
          ></p>
        </div>

        <img
          src="/images/icons-8-doppelt-runter-21-schwarz.png"
          alt=""
          className="animate-bounce secondary:w-[80px] sm:w-[50px] w-[32px] m-auto mt-8"
        />

        <div
          className="text-center secondary:text-none mt-4 p-7 primary:px-40 overflow-anywhere"
          dangerouslySetInnerHTML={{
            __html: landingPageData.transformed_questionaireIntroductionText,
          }}
        ></div>

        <TypeButton
          className="mx-4 mt-4 mb-14"
          type={type}
          text={questionaireButtonText}
          onClick={() => {
            try {
              googleAnalyticsHandler("qf");
            } catch (error) {
              console.error("Analytics error (Vercel ENV):", error);
            }
            navigate(
              `/questionare/${type}/${landingPageId}/${promoter_id}/${clickid}`
            );
          }}
        />

        <Footer
          user={user}
          ladingPageId={landingPageId}
          landingPageData={landingPageData}
          promoter_id={promoter_id}
          type={type}
        />
      </div>
    </div>
  );
};

export default StromGasView;
