import React from "react";
import { sendEventUser } from "../../../../../utils/analytics";
import { aboTarif } from "../../../../../utils/divers";
import { replaceText } from "../../../../../utils/replaceText";
import { imageBackground } from "../../../../../utils/setImageBackground";
import Video from "../../shared/Video";
import WhatsappButton from "../../shared/WhatsappButton";

const HeroFinance = ({ landingPageData, promoter }) => {
  const {
    transformed_accompanyingTextOne,
    transformed_accompanyingTextTwo,
    transformed_accompanyingTextThree,
    transformed_introductoryText,
    transformed_whatsappText,
    whatsappNumber,
    background_image,
    logo,
    primary_image,
    whatsappButtonTextOne,
    persentationVideo,
    thumbnail,
    activateWhatsApp,
    activateBackgroundIntroductoryText,
    introductoryText,
    logoSrc,
    userSubscription,
    activate_wa1,
  } = landingPageData;

  const replacedIntroText = promoter
    ? introductoryText
        .replace("{Promoter Vorname}", `${promoter.firstname}`)
        .replace("{Promoter Nachname}", `${promoter.lastname}`)
    : introductoryText
        .replace("{Promoter Vorname}", `{Promoter Vorname}`)
        .replace("{Promoter Nachname}", `{Promoter Vorname}`);
  landingPageData[`transformed_accompanyingTextOne`] = replaceText(
    transformed_accompanyingTextOne,
    {
      "{Promoter Vorname}": ` ${promoter.firstname} `,
      "{Promoter Nachname}": ` ${promoter.lastname} `,
    }
  );
  const withBackground = (set) => {
    return set ? "bg-[#000000] p-1 rounded-sm bg-opacity-50" : "";
  };
  return (
    <>
      <div
        className={`relative w-full p-5 ${
          aboTarif({ subscription: userSubscription }) < 2
            ? "pt-[100px] secondary:pt-[50px]"
            : "pt-16 secondary:pt-[80px]"
        } secondary:px-32 overflow-hidden`}
      >
        {aboTarif({ subscription: userSubscription }) >= 2 && logoSrc && (
          <img
            src={logoSrc}
            alt="logo"
            className="absolute h-[60px] secondary:h-[120px] left-1/2 translate-x-[-50%] top-2"
          />
        )}
        {aboTarif({ subscription: userSubscription }) < 2 && (
          <img
            src={"/images/logo.png"}
            alt="logo"
            className="absolute w-[50px] left-1/2 translate-x-[-50%] top-10"
          />
        )}

        <div
          style={{
            ...imageBackground(background_image),
          }}
          className="absolute top-0 left-0 right-0 bottom-0 z-[-1]"
        />
        <div className="flex flex-col secondary:gap-24 secondary:flex-col max-w-[1600px] m-auto">
          <div className="flex-1 text-white secondary:flex secondary:flex-col secondary:justify-between">
            <div
              className={`relative mb-10 font-semibold overflow-anywhere secondary:mt-14 whitespace-pre-line secondary:leading-[25px] leading-[25px] text-wrap ${withBackground(
                transformed_introductoryText != "" &&
                  activateBackgroundIntroductoryText
              )}`}
              dangerouslySetInnerHTML={{ __html: replacedIntroText }}
            ></div>
            <div className="flex secondary:flex-row secondary:gap-4 justify-center secondary:items-center secondary:justify-between">
              <Video
                url={persentationVideo}
                thumbnail={thumbnail}
                className="hidden secondary:block pt-8 secondary:!w-[100%] !m-0 text-white"
                type="finance"
                accompanyingTextOne={
                  landingPageData.transformed_accompanyingTextOne
                }
                activateBackgroundAccompanyingTextOne={
                  landingPageData.activateBackgroundAccompanyingTextOne
                }
                heading={landingPageData.transformed_introVideoHeader}
                landingPageId={landingPageData._id}
                videoTextBG={landingPageData.activateBackgroundVideoText}
              />
            </div>
          </div>

          {/* * Foto
          <div
            className="size-[400px] rounded-xl hidden secondary:block !bg-top"
            style={
              primary_image != "" ? { ...imageBackground(primary_image) } : {}
            }
          ></div> */}

          {/** Video */}
          <Video
            url={landingPageData.persentationVideo}
            thumbnail={landingPageData.thumbnail}
            className="secondary:hidden !w-[100%]"
            textWhite
            type="finance"
            heading={landingPageData.transformed_introVideoHeader}
            textBackground={landingPageData.activateTextWithBackground}
            videoTextBG={landingPageData.activateBackgroundVideoText}
          />
          <div className="secondary:hidden">
            {activate_wa1 && (
              <WhatsappButton
                number={whatsappNumber}
                whatsappText={transformed_whatsappText}
                className="pt-14 secondary:hidden"
                text={whatsappButtonTextOne}
                activateWhatsApp={activateWhatsApp}
                type="finance"
                sendGoogleAnalytics={() =>
                  sendEventUser({
                    category: "button",
                    action: `click_wa1`,
                    label: "wa1",
                    value: 1,
                    landingPageId: landingPageData._id,
                  })
                }
              />
            )}
          </div>
          <a
            id="bounce-button"
            href="#middle"
            className="secondary:hidden block mt-auto pt-14"
          >
            <img
              src="/images/icons-8-doppelt-runter-21.png"
              alt=""
              className="animate-bounce secondary:w-[80px] sm:w-[50px] w-[32px] m-auto"
            />
          </a>
        </div>
      </div>
      <div id="middle" />
    </>
  );
};

export default HeroFinance;
